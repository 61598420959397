import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

import Card from './components/Card';
import Banner from './components/Banner';
import StoresLinks from './components/StoresLinks';
import Today from './components/Today';
import Popper from './components/Popper';
import AppDownloadPrompt from "./components/AppDownloadPrompt";

function App() {
  const [data, setData] = useState();
  const [page, setPage] = useState(
    <main className="preload">
      <h1>Almost there ...</h1>
    </main>
  );
  const [partners, setPartners] = useState([]);
  const getData = async () => {
    axios.get('/texts.json')
      .then(function (response) {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPartners = async () => {
    axios.get('/partners.json')
      .then(function (response) {
        setPartners(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getData();
    getPartners();
  }, []);

  useEffect(() => {
    if (data && partners) {
      setPage(
        <>
          <header>
            <div className="container">
              <Banner data={ data }/>
              <nav>
                <div className="logo"></div>
              </nav>
              <StoresLinks data={ data }/>
            </div>
          </header>

          <main>
            <div className="container">
              <Today data={ data }/>
              <p>{ data.textAbovePartners }</p>
              <section className="partners">
                { partners.map((partner) => <Card data={ data } partner={ partner } key={ partner.id }/>) }
              </section>
              <div dangerouslySetInnerHTML={ { __html: data.textBelowPartners } }></div>
            </div>
          </main>

          <footer>
            <div className="container">
              <Banner data={ data }/>
              <StoresLinks data={ data }/>
            </div>
          </footer>
          <Popper data={ data } partners={ partners }/>
          <AppDownloadPrompt data={ data }/>
        </>
      );
    }
  }, [data, partners]);

  return (
    <>
      { page }
    </>
  );
}

export default App;
