import { useState, useEffect } from 'react';
import Card from './Card';

function Popper({ data, partners }) {
  const [showPopper, setShowPopper] = useState(false);

  useEffect(() => {
    if (data.textPopper === 'disable') {
      return;
    }
    const timer = setTimeout(() => {
      setShowPopper(true);
    }, 450);
    return () => clearTimeout(timer);
  }, [data]);

  const handleClose = () => {
    setShowPopper(false);
  };

  return showPopper ? (
    <section className="popper">
      <div className="popper-wrapper">
        <div className="popper-header">
          <span id="popper-close" onClick={ handleClose }>X</span>
        </div>
        <div className="popper-cards">
          <p className="popper-text">{ data.textPopper }</p>
          { partners.map((partner) => {
            if (partner.isSelected === true) {
              return <Card data={ data } partner={ partner } key={ partner.id }/>;
            } else {
              return <div/>;
            }
          }) }
        </div>
      </div>
    </section>
  ) : null;
}

export default Popper;
