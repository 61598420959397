import { isIOS, isAndroid } from 'react-device-detect';
import { useState } from "react";

function AppDownloadPrompt({ data }) {
  const [showPrompt, setShowPrompt] = useState(false);

  const handleClose = () => {
    setShowPrompt(false);
  };

  if (showPrompt && isIOS) {
    return (
      <div className="download-prompt">
        <p>{ data.downloadApp }</p>
        <span><img alt="app logo" src="/logo.png"/>{ data.brandName }</span>
        <a className="download-prompt__to-store" href={ data.appIOS }>App Store</a>
        <span><img alt="browser logo" src="assets/logo-safari.png"/>Browser</span>
        <button className="download-prompt__to-browser" onClick={ handleClose }>{ data.continue }</button>
      </div>
    );
  } else if (showPrompt && isAndroid) {
    return (
      <div className="download-prompt">
        <p>{ data.downloadApp }</p>
        <span><img alt="app logo" src="/logo.png"/>{ data.brandName }</span>
        <a href={ data.appAndroid }>Google Play</a>
        <span><img alt="browser logo" src="assets/logo-chrome.png"/>Browser</span>
        <button className="download-prompt__to-browser" onClick={ handleClose }>{ data.continue }</button>
      </div>
    );
  }

  return null;
}

export default AppDownloadPrompt;
