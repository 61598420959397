function StoresLinks({ data }) {
  return <section className="stores-links">
    {/*<a target="_blank" rel="noreferrer" href={ data.appAndroid }>
      <img src="assets/dl_google.png" alt=""/>
    </a>
    <a target="_blank" rel="noreferrer" href={ data.appIOS }>
      <img src="assets/dl_apple.png" alt=""/>
    </a>*/}
  </section>;
}

export default StoresLinks;
