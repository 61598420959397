function Card({ data, partner }) {

  return <div className="card" key={ partner.id } id={ partner.id }>
    <div className="card-logo">
      <img src={ partner.image } alt=""/>
    </div>
    <div className="card-row">{ data.amount }<span>{ partner.amount }</span></div>
    <div className="card-row">{ data.rate }<span>{ partner.rate }</span></div>
    <div className="card-row">{ data.age }<span>{ partner.age }</span></div>
    <hr/>
    <div className="card-row description"><span>{ partner.description }</span></div>
    <a href={ partner.link } target="_blank" rel="noreferrer">{ data.partnersButton }</a>
  </div>;
}

export default Card;
